.benefit__banner {
    height: calc(var(--vh, 1vh) * 100);
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.banner__title {
    max-width: 60vw;
    text-align: center;
    font-size: var(--extralg-font-size);
}

.lebahcp {
    position: absolute;
    bottom: -1px;
    width: 100vw;
}

.icpcwf {
    position: absolute;
    z-index: -10;
    height: 100vh;
    width: 100%;
    object-fit: cover;
    filter: brightness(40%);
}

.why__cp {
    background-color: var(--white);
    color: var(--dark);
}

.cp__benefits {
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
}

.jb__benefits {
    grid-template-columns: 1fr 1fr 1fr;
    row-gap: 2rem;
    column-gap: 3rem;
    align-items: stretch;
}

@media screen and (max-width: 768px) {
    .jb__benefits {
        grid-template-columns: 1fr 1fr;
        row-gap: 1.5rem;
        column-gap: 2rem;
    }
}

@media screen and (max-width: 576px) {
    .jb__benefits {
        row-gap: 1.2rem;
        column-gap: 1.5rem;
    }
}

@media screen and (max-width: 480px) {
    .jb__benefits {
        grid-template-columns: 1fr;
        row-gap: 1rem;
        column-gap: 1rem;
    }

    .banner__title {
        font-size: 1.5rem;
    }

    .cp__benefits {
        grid-template-columns: 1fr;
        gap: 0.7rem;
    }
}
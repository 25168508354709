.timeline {
    text-align: center;
}

.timeline .timeline__name {
    margin-top: 0.4rem;
    margin-bottom: 0.2rem;
}

.timeline .timeline__date {
    margin-bottom: 0.5rem;
    font-size: var(--small-font-size);
}

.timeline .timeline__desc {
    font-size: var(--small-font-size);
}

@media screen and (max-width: 1024px) {
    .timeline {
        text-align: left;
        width: 80%;
        padding-left: 4.9rem;
    }

    .timeline .timeline__date {
        font-size: var(--regular-font-size);
    }
    
    .timeline .timeline__desc {
        font-size: var(--regular-font-size);
    }

    .timeline::before {
        content: '';
        width: 1rem;
        height: 1rem;
        background-color: var(--yellow-yolk);
        border-radius: 100%;
        transform: translateY(60%) translateX(-3.75rem);
        position: absolute;
        box-shadow: 0px 0px 5px 2px var(--secondary);
    }
}

@media screen and (max-width: 768px) {
    .timeline {
        width: 100%;
        padding-left: 5.2rem;
    }

    .timeline::before {
        transform: translateY(60%) translateX(-4rem);
    }
}

@media screen and (max-width: 576px) {
    .timeline::before {
        transform: translateY(60%) translateX(-4.2rem);
    }

    .timeline .timeline__date {
        margin-bottom: 0.4rem;
    }
}

@media screen and (max-width: 480px) {
    .timeline::before {
        width: 1.2rem;
        height: 1.2rem;
    }

    .timeline::before {
        transform: translateY(60%) translateX(-4.1rem);
    }
}

@media screen and (max-width: 367px) {
    .timeline::before {
        transform: translateY(60%) translateX(-3.8rem);
    }
}

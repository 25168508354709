.jbBenefit__wrapper {
    width: 100%;
}

.title__container {
    display: flex;
    gap: 0.8rem;
}

.jbBenefit__name {
    font-size: var(--h3-font-size);
    font-weight: var(--semibold);
    text-align: center;
    width: 100%;
}

.dropdown__btn {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: var(--white);
    padding: 1rem 2rem;
    border-radius: var(--border-radius);
    transition: all 0.3s ease-in-out;
}

.dropdown__btn.open, 
.dropdown__btn:hover {
    box-shadow: 0px 0px 15px var(--white);
}

.title__container {
    min-height: 3rem;
}

.explain__container {
    display: grid;
    transition: all 0.3s ease-in-out;
    background-color: var(--white);
    color: var(--dark);
}

.explain__container.close {
    grid-template-rows: 0fr;
    overflow: hidden;
    opacity: 0;
}

.explain__container.open {
    grid-template-rows: 1fr;
    opacity: 1;
    padding: 0.3rem 0rem 1rem 0rem;
}

.jbBenefit__explain {
    overflow: hidden;
    text-align: center;
}

.title__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.dropdown-icon {
    position: relative;
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    align-items: center;
}

.dropdown-icon span {
    display: inline-block;
    position: absolute;
    width: calc(50% * 1.3);
    height: 3px;
    background-color: var(--dark);
    border-radius: 2px;
    transition: all 0.3s ease-in-out;
}

.dropdown-icon.close span:nth-child(1) {
    transform: rotate(45deg);
    left: 0;
}

.dropdown-icon.close span:nth-child(2) {
    transform: rotate(-45deg);
    right: 0;
}

.dropdown-icon.open span:nth-child(1) {
    transform: rotate(135deg);
    left: 0;
}

.dropdown-icon.open span:nth-child(2) {
    transform: rotate(-135deg);
    right: 0;
}

@media screen and (max-width: 1024px) {
    .dropdown__btn {
        border-radius: 12px;
    }
}

@media screen and (max-width: 824px) {
    .title__container {
        min-height: 3.8rem;
    }
}

@media screen and (max-width: 768px) {
    .title__container {
        min-height: 2.2rem;
    }

    .dropdown__btn {
        padding: 0.8rem 1.6rem;
    }
}

@media screen and (max-width: 480px) {
    .title__container {
        min-height: 3.5rem;
        gap: 0.15rem;
    }

    .dropdown__btn {
        padding: 0.2rem 1.5rem;
    }
    
}
p {
    margin-bottom: 0.5rem;
}

a {
    color: var(--medium-accent);
    transition: 0.2s;
}

a:hover {
    color: var(--dark-accent);
}


.about {
    background-color: var(--white);
    color: var(--dark);
}

.about__title {
    color: var(--dark);
}

.cp__section {
    margin-top: 1rem;
    margin-bottom: 4rem;
    width: 70%;
}

.cp__highlight {
    color: var(--primary);
}

.jb__section {
    text-align: right;
    margin-left: auto;
    width: 70%;
}

.jb__highlight {
    color: var(--primary);
}

.jb__section .links-container {
    justify-content: flex-end;
}

@media screen and (max-width: 768px) {
    .cp__section {
        margin-bottom: 3rem;
        width: 100%;
    }

    .jb__section {
        width: 100%;
    }
}
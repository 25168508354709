.learn {
    position: relative;
    overflow: hidden;
}

.jalansetapak {
    position: absolute;
    z-index: -1;
    top: 3rem;
    object-fit: cover;
}

.learn__start {
    display: flex;
    flex-direction: column;
} 

.learn__start h3 {
    margin-bottom: 3rem;
}

.learn__start-for {
    display: flex;
    flex-direction: row;
    gap: 3rem;
}

.for-content {
    width: 40%;
    text-align: justify;
    border-radius: var(--border-radius);
    box-shadow: 0 0 10px 5px var(--white);
    backdrop-filter: blur(20px); 
    padding: 1.5rem 2rem;
}

.for-content h3 {
    margin-bottom: 0.3rem;
}


.learn__otherSources {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;
    background-color: var(--gray);
    mask: linear-gradient(transparent 0%, var(--gray) 8%, var(--gray) 92%, transparent 100%);
}



.otherSources-text {
    flex: 1;
}


.learn__oj {
    display: flex;
    flex-direction: row;
    gap: 2rem;
}

.learn__oj-main,
.learn__oj-notes {
    background-color: var(--white);
    border-radius: var(--border-radius);
    color: var(--dark);
    padding: 2rem 3rem;
}

.learn__oj-main {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.learn__oj-main h1 {
    flex: 1.5;
    margin-right: 1rem;
}

.learn__oj-main-text {
    flex: 3;
    display: flex;
    flex-direction: column;
}

.oj-recommendations {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 2rem;
    margin-top: 1rem;
}

.oj__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
}

.oj__container img {
    max-width: 4.2rem;
    margin-bottom: 0.8rem;
}

.oj__container span {
    color: var(--dark);
}

.learn__oj-notes {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.learn__oj-notes h2 {
    margin-bottom: 0.5rem;
}

.learn__important {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 4.5rem;
    backdrop-filter: blur(10px); 
    background-color: rgb(0 0 0 / 30%);
    mask: linear-gradient(transparent 0%, black 12%);
}

.learn__important-text {
    text-align: center;
}

.practice__cp {
    line-height: 1;
    color: var(--primary);
    margin-bottom: 1rem;
}

.learn a {
    color: var(--primary);
}

.learn a:hover {
    color: var(--secondary);
}

@media screen and (max-width: 1214px) {
    .learn__otherSources {
        align-items: center;
        gap: 1rem;
    }
}

@media screen and (max-width: 1024px) {
    .learn__oj-main,
    .learn__oj-notes {
        padding: 1.5rem 2.5rem;
    }

    .oj__container img {
        max-width: 4rem;
    }

    .learn__oj {
        gap: 1.5rem;
    }
}

@media screen and (max-width: 900px) {
    .learn__oj-main,
    .learn__oj-notes {
        padding: 2rem 2.5rem;
    }

    .learn__oj {
        display: flex;
        flex-direction: column;
        gap: 2rem;
    }

    .learn__important {
        gap: 1.5rem;
    }

    .learn__important img {
        transform: scale(0.8);
    }
} 

@media screen and (max-width: 768px) {

    .learn__oj-main {
        flex-direction: column;
        align-items: flex-start;
    }

    .learn__oj-main h1 {
        margin-bottom: 0.9rem;
    }

    .learn__important {
        gap: 0rem;
    }

    .learn__important img {
        transform: scale(0.6);
    }

    .oj-recommendations {
        justify-content: center;
    }

    .oj__container img {
        max-width: 3.8rem;
    }

    .for-content {
        width: 45%;
        border-radius: 10px;
        padding: 1.3rem 1.5rem;
    }
}

@media screen and (max-width: 480px) {
    .learn__start-for {
        flex-direction: column;
        gap: 1.5rem;
    }

    .for-content {
        width: 100%;
    }

    .learn__otherSources {
        display: flex;
        gap: 1rem;
        flex-direction: column;
    }

    .otherSources-text {
        flex: none;
    }

    .oj__container img {
        max-width: 3.5rem;
        flex-wrap: wrap;
    }

    .learn__oj-main-text {
        flex: none;
        display: flex;
        flex-direction: column;
    }

    .jalansetapak {
        top: 5.5rem;
    }

    .learn__otherSources {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 2rem;
        background-color: var(--gray);
        mask: linear-gradient(transparent 0%, var(--gray) 3%, var(--gray) 97%, transparent 100%);
    }
} 
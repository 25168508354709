.cpBenefit__wrapper {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
}

.cpBenefit__wrapper i {
    font-size: 2rem;
    width: 3rem;
    height: 3rem;
    line-height: 1;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
}

.cpBenefit__name {
    font-size: var(--h3-font-size);
}

@media screen and (max-width: 768px) {
    .cpBenefit__wrapper {
        gap: 0.8rem;
    }

    .cpBenefit__icon {
        font-size: 1.8rem;
    }
}

@media screen and (max-width: 480px) {
    .cpBenefit__wrapper {
        gap: 0.6rem;
    }

    .cpBenefit__icon {
        font-size: 1.8rem;
    }
}